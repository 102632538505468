import { initialize } from './helpers/init'
import loadBundles from './helpers/loadBundles'

import 'focus-visible'
import fitvids from 'fitvids'

/* Import components as needed */
import InstagramShare from './components/instagramShare'
import Lightbox from './components/lightbox'
import Header from './components/header'
import ScrollAnimationHome from './components/scrollAnimationHome'
import ScrollAnimationCategory from './components/scrollAnimationCategory'
import ScrollAnimationProject from './components/scrollAnimationProject'

document.body.classList.remove('no-js')

document.fonts.onloadingdone = () => {
  document.body.classList.add('fonts-loaded')
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    document.documentElement.classList.remove('is-loading')
    document.documentElement.classList.add('is-loaded-complete')
  }
}

/* Initialize components as needed */
initialize('instagram-share', InstagramShare)
initialize('header', Header)
initialize('lightbox', Lightbox)

const initPageTransitions = () => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    /* Page transitions */
    // import('./helpers/pageTransitions').then((obj) => {
    //   const revealOnEnter = obj.default
    //   revealOnEnter()
    // })

    /* Scroll nav */
    const scrollNavigationElement = document.querySelector(
      '[data-behaviour="scroll-navigation"]'
    )

    if (scrollNavigationElement) {
      import('./components/scrollNavigation').then((component) => {
        const el = document.querySelector(
          '[data-behaviour="scroll-navigation"]'
        )
        component.render(el)
      })
    }
  } else {
    document.body.classList.add('no-observer')
  }
}

initPageTransitions()
fitvids({
  ignore: '.wp-has-aspect-ratio iframe',
})

/*
  ** Conditionally load bundles **
  ---
  Only do this if you have large components or dependencies that you don't want to load on every page (e.g. a Vue application). Otherwise import and initialise them in the normal way, above.
*/
const bundles = [
  {
    selector: 'parallax',
    fileName: 'parallax',
  },
  {
    selector: 'gallery',
    fileName: 'customGallery',
  },
]
loadBundles(bundles)

/* Dynamically import GSAP modules */
import('gsap').then((object) => {
  const gsap = object.default

  import('gsap/ScrollTrigger').then((ScrollTrigger) => {
    gsap.registerPlugin(ScrollTrigger)

    /* Home */
    const homepageEl = document.querySelector(
      '[data-behaviour="scroll-animation-home"]'
    )

    if (homepageEl) {
      return new ScrollAnimationHome(homepageEl, {
        gsap,
        scrollTrigger: ScrollTrigger,
      })
    }

    /* Category */
    const categoryPageEl = document.querySelector(
      '[data-behaviour="scroll-animation-category"]'
    )

    if (categoryPageEl) {
      return new ScrollAnimationCategory(categoryPageEl, {
        gsap,
        scrollTrigger: ScrollTrigger,
      })
    }

    /* Project */
    const projectPageEl = document.querySelector(
      '[data-behaviour="scroll-animation-project"]'
    )

    if (projectPageEl) {
      return new ScrollAnimationProject(projectPageEl, {
        gsap,
        scrollTrigger: ScrollTrigger,
      })
    }
  })
})
