class FadeInSections {
  constructor(el, args) {
    this.gsap = args.gsap
    this.scrollTrigger = args.scrollTrigger
    this.sections = [...el.querySelectorAll('[data-animate]')]
  }

  get startValue() {
    if (window.innerHeight < 800) {
      return window.innerHeight * 0.9
    }

    return window.innerHeight - 80
  }

  init() {
    this.sections.forEach((el) => {
      this.gsap.set(el, {
        opacity: 0,
        y: 50,
      })

      const tl = this.gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: `top ${this.startValue}`,
          toggleActions: 'play none resume reverse',
        },
      })

      tl.to(el, {
        opacity: 1,
        y: 0,
      })
    })
  }
}

export default FadeInSections
