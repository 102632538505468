// import gsap from 'gsap'
import FadeInSections from './fadeInSections'

const prefersReducedMotion = window.matchMedia(
  '(prefers-reduced-motion: reduce)'
)

class ScrollAnimationCategory {
  constructor(el, args) {
    this.gsap = args.gsap
    this.scrollTrigger = args.scrollTrigger

    if (prefersReducedMotion.matches) return

    this.pageHeaderContent = el.querySelector('[data-page-header-content]')
    this.categoryLink = el.querySelector('[data-category-link]')
    this.sectionFade = new FadeInSections(el, args)
    this.sectionFade.init()
    this.fadeInPageHeader()
  }

  fadeInPageHeader() {
    if (!this.pageHeaderContent) return

    this.gsap.set(this.pageHeaderContent, {
      opacity: 0,
      y: 50,
    })

    this.gsap.set(this.categoryLink, {
      opacity: 0,
      y: 50,
    })

    this.gsap.to(this.pageHeaderContent, {
      opacity: 1,
      y: 0,
    })

    this.gsap.to(this.categoryLink, {
      opacity: 1,
      y: 0,
      delay: 0.4,
    })
  }
}

export const render = (el) => new ScrollAnimationCategory(el)
export default ScrollAnimationCategory
