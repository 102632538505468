import FadeInSections from './fadeInSections'
import StaggeredFade from './staggeredFade'

const prefersReducedMotion = window.matchMedia(
  '(prefers-reduced-motion: reduce)'
)

class ScrollAnimationHome {
  constructor(el, args) {
    this.gsap = args.gsap
    this.scrollTrigger = args.scrollTrigger

    this.el = el
    this.quote = el.querySelector('[data-quote-animated]')

    this.sectionFade = new FadeInSections(el, args)
    this.fadeInCategories = new StaggeredFade(
      '[data-grid-item="categories"]',
      args
    )
    this.fadeInJudges = new StaggeredFade('[data-grid-item="judges"]', args)
    this.fadeInInfoCards = new StaggeredFade('[data-card]', args)

    if (prefersReducedMotion.matches) return

    this.init()
  }

  get startValue() {
    if (window.innerHeight < 800) {
      return window.innerHeight * 0.8
    }

    return window.innerHeight - 200
  }

  init() {
    this.sectionFade.init()
    this.fadeInCategories.init()
    this.fadeInJudges.init()
    this.fadeInInfoCards.init()
    this.fadeInQuote()
  }

  fadeInQuote() {
    if (!this.quote) return

    this.quoteImage = this.quote.querySelector('[data-image]')
    this.quoteBody = this.quote.querySelector('[data-body]')

    if (this.quoteImage) {
      this.gsap.set(this.quoteImage, {
        opacity: 0,
        x: -100,
      })
    }

    if (this.quoteBody) {
      this.gsap.set(this.quoteBody, {
        opacity: 0,
        x: 100,
      })
    }

    const tl = this.gsap.timeline({
      scrollTrigger: {
        trigger: this.quote,
        toggleActions: 'play none resume reverse',
      },
    })

    tl.to(this.quoteImage, {
      opacity: 1,
      x: 0,
    }).to(this.quoteBody, {
      opacity: 1,
      x: 0,
    })
  }
}

export const render = (el, args) => new ScrollAnimationHome(el, args)
export default ScrollAnimationHome
