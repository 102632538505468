import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { trapFocus } from '../helpers/trapFocus'

class Lightbox {
  constructor(el) {
    this.trigger = el.querySelector('[data-btn]')
    this.lightbox = document.querySelector('[data-lightbox]')
    this.closeButton = this.lightbox.querySelector('[data-lightbox-close]')
    this.contentArea = this.lightbox.querySelector('[data-lightbox-content]')
    this.target = this.trigger.dataset.target
    this.content = el.querySelector('[data-content]')
    this.html = null
    this.hidden = true

    // Events
    this.handleLightboxCloseClick = this.handleLightboxClick.bind(this)
    this.trigger.addEventListener('click', this.handleClick.bind(this))
    this.closeLightbox = this.close.bind(this)

    document.body.classList.add('has-lightbox')
  }

  open() {
    this.lightbox.hidden = false

    if (!this.html) {
      this.html = this.content.innerHTML
    }

    this.contentArea.innerHTML = `${this.html}`
    this.content.innerHTML = ''
    this.trigger.setAttribute('aria-expanded', true)
    this.lightbox.setAttribute('aria-hidden', false)

    setTimeout(() => {
      this.lightbox.classList.add('is-active')

      // Disable scrolling on body while modal is open
      this.scrollableEl = this.contentArea.children[0]
      disableBodyScroll(this.scrollableEl)

      this.closeButton.focus()
      this.attachKeyDownEvents()

      // Find any close buttons that were previously hidden
      this.attachCloseEvents()
    }, 100)
  }

  attachKeyDownEvents() {
    this.lightbox.addEventListener('keydown', (e) => {
      const escIsPressed = e.keyCode === 27 || e.key === 'Esc'

      trapFocus(e, this.lightbox)

      if (escIsPressed) {
        this.close(e)
      }
    })
  }

  attachCloseEvents() {
    const closeButtons = [
      ...this.lightbox.querySelectorAll('[data-lightbox-close]'),
    ]

    closeButtons.forEach((el) => {
      el.addEventListener('click', this.closeLightbox)
    })

    this.lightbox.addEventListener('click', this.handleLightboxCloseClick)
  }

  removeCloseEvents() {
    const closeButtons = [
      ...this.lightbox.querySelectorAll('[data-lightbox-close]'),
    ]

    closeButtons.forEach((el) => {
      el.removeEventListener('click', this.closeLightbox)
    })

    this.lightbox.removeEventListener('click', this.handleLightboxCloseClick)
  }

  close(e) {
    e.preventDefault()
    e.stopPropagation()

    this.scrollableEl = this.contentArea.children[0]
    this.lightbox.classList.remove('is-active')
    this.removeCloseEvents()

    // Enable body scrolling
    enableBodyScroll(this.scrollableEl)

    setTimeout(() => {
      this.trigger.setAttribute('aria-expanded', false)
      this.lightbox.setAttribute('aria-hidden', true)
      this.lightbox.hidden = true
      this.contentArea.innerHTML = ''
      this.content.innerHTML = `${this.html}`
      this.trigger.focus()
    }, 300)
  }

  handleClick(e) {
    e.preventDefault()

    if (this.hidden) {
      this.open()
    }
  }

  handleLightboxClick(e) {
    const targetIsWrapper = !e.target.closest('[data-inner]')

    if (targetIsWrapper) {
      this.close(e)
    }
  }
}

export default Lightbox
