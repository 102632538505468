import FadeInSections from './fadeInSections'

const prefersReducedMotion = window.matchMedia(
  '(prefers-reduced-motion: reduce)'
)

class ScrollAnimationProject {
  constructor(el, args) {
    if (prefersReducedMotion.matches) return

    this.sectionFade = new FadeInSections(el, args)
    this.sectionFade.init()
  }
}

export const render = (el) => new ScrollAnimationProject(el)
export default ScrollAnimationProject
