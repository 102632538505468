import throttle from 'lodash.throttle'

class Header {
  constructor(el) {
    this.el = el
    this.throttledOnScroll = throttle(this.onScroll.bind(this), 30)

    window.addEventListener('scroll', this.throttledOnScroll)
  }

  onScroll() {
    if (window.scrollY > 100) {
      document.body.classList.add('is-scrolled')
    } else {
      document.body.classList.remove('is-scrolled')
    }
  }
}

export default Header
