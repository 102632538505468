class StaggeredFade {
  constructor(selector, args) {
    this.items = selector
    this.gsap = args.gsap
    this.scrollTrigger = args.scrollTrigger
  }

  init() {
    this.gsap.set(this.items, {
      opacity: 0,
      y: 50,
    })

    const tl = this.gsap.timeline({
      scrollTrigger: {
        trigger: this.items,
        toggleActions: 'play none play reverse',
      },
    })

    tl.to(this.items, {
      opacity: 1,
      y: 0,
      stagger: 0.15,
      duration: 0.4,
    })
  }
}

export default StaggeredFade
